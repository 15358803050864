import type { MaybeElementRef } from '@vueuse/core'
import type { ComputedRef } from 'vue'

function useElementViewScrollProgress(
  containerElement: ComputedRef<HTMLElement | undefined>,
  scrollerElement: MaybeElementRef<HTMLElement>,
) {
  const isSmallDevice = useMediaQuery('(max-width: 768px)')

  const scrollerContainer = computed(() => unrefElement(scrollerElement)?.parentElement)
  const scroller = computed(() => unrefElement(scrollerElement))
  const container = computed(() => unrefElement(containerElement))

  const { top: containerTop, height: containerHeight } = useElementBounding(containerElement)
  const { height: scrollerContainerHeight, width: scrollerContainerWidth } =
    useElementBounding(scrollerContainer)
  const { height: scrollerHeight } = useElementBounding(scrollerElement)

  const progressPercentage = computed(() => {
    if (!container.value) return 0
    if (isSmallDevice.value) return 0

    const progress =
      Math.max(0, containerTop.value * -1) / (containerHeight.value - window.innerHeight)
    const clampedProgress = Math.min(Math.max(progress, 0), 1)
    return Number.isNaN(clampedProgress) || !clampedProgress ? 0 : clampedProgress
  })

  const containerElementHeight = computed(() => {
    if (!scrollerHeight.value) return 0

    return Math.round(
      Math.max(containerHeight.value, scrollerHeight.value - scrollerContainerHeight.value),
    )
  })

  watchEffect(() => {
    if (!container.value || !scroller.value || containerElementHeight.value <= 0) return
    if (isSmallDevice.value) {
      container.value.style.height = 'auto'
      return
    }

    container.value.style.height = `${containerElementHeight.value}px`
  })

  return {
    progressPercentage,
    scrollerContainerHeight,
    scrollerContainerWidth,
  }
}

export default useElementViewScrollProgress

<script lang="ts" setup>
type DeviceType = 'desktop' | 'mobile' | 'tablet'
type Orientation = 'landscape' | 'portrait'

const props = defineProps<{
  device: {
    device_type: DeviceType
    orientation?: Orientation | 'auto'
    size: keyof typeof maxHeightBySize
    image_url: string
    frame_color: string
  }
  containerRef?: HTMLElement | undefined
  cover?: boolean
}>()

const container = computed(() => props.containerRef)
const scrollRef = ref<HTMLImageElement | null>(null)

const orientationDefaults = {
  desktop: 'landscape',
  mobile: 'portrait',
  tablet: 'landscape',
} satisfies Record<DeviceType, Orientation>

const orientation = computed(() =>
  props.device.orientation && props.device.orientation !== 'auto'
    ? props.device.orientation
    : orientationDefaults[props.device.device_type],
)

const devices = {
  desktop: {
    width: 1229,
    height: 659,
    // imageWidth: 1000,
    // imageHeight: 622,
    imageLeft: 114,
    imageTop: 10,
    imageRight: 115,
    imageBottom: 27,
    landscapeBorderRadius: '1.6273393%/3.03490137%', // ['1.6273393%/3.03490137%', '1.6273393%/3.03490137%', '0', '0'],
    portraitBorderRadius: '3.03490137%/1.6273393%', // ['3.03490137%/1.6273393%', '0', '0', '3.03490137%/1.6273393%'],
    landscapePath:
      'M 1123 639.799988 L 1123 636.099976 L 1123 632.099976 L 1123 43.200012 C 1123 19.900024 1104 0.900024 1080.699951 0.900024 L 147.299988 0.900024 C 124 0.900024 105 19.900024 105 43.200012 L 105 632 L 105 636 L 105 639.700012 L 0 639.700012 L 0 644.200012 C 0 650.700012 5.299988 656 11.799988 656 L 69.5 656 C 70.5 657.799988 72 659 73.700012 659 L 145.700012 659 C 147.399994 659 148.899994 657.799988 149.899994 656 L 1078.599976 656 C 1079.599976 657.799988 1081.099976 659 1082.800049 659 L 1154.800049 659 C 1156.5 659 1158 657.799988 1159 656 L 1216.400024 656 C 1222.900024 656 1228.199951 650.700012 1228.199951 644.200012 L 1228.199951 639.700012 L 1123 639.700012 Z M 114 43.200012 C 114 24.900024 128.899994 9.900024 147.299988 9.900024 L 1080.800049 9.900024 C 1099.099976 9.900024 1114.099976 24.799988 1114.099976 43.200012 L 1114.099976 631.599976 L 114 631.599976 L 114 43.200012 Z',
    portraitPath:
      'M 639.799988 106 L 636.099976 106 L 632.099976 106 L 43.200012 106 C 19.900024 106 0.900024 125 0.900024 148.300049 L 0.900024 1081.699951 C 0.900024 1105 19.900024 1124 43.200012 1124 L 632 1124 L 636 1124 L 639.700012 1124 L 639.700012 1229 L 644.200012 1229 C 650.700012 1229 656 1223.699951 656 1217.199951 L 656 1159.5 C 657.799988 1158.5 659 1157 659 1155.300049 L 659 1083.300049 C 659 1081.599976 657.799988 1080.099976 656 1079.099976 L 656 150.400024 C 657.799988 149.400024 659 147.900024 659 146.199951 L 659 74.199951 C 659 72.5 657.799988 71 656 70 L 656 12.599976 C 656 6.099976 650.700012 0.800049 644.200012 0.800049 L 639.700012 0.800049 L 639.700012 106 Z M 43.200012 1115 C 24.900024 1115 9.900024 1100.099976 9.900024 1081.699951 L 9.900024 148.199951 C 9.900024 129.900024 24.799988 114.900024 43.200012 114.900024 L 631.599976 114.900024 L 631.599976 1115 L 43.200012 1115 Z',
  },
  mobile: {
    width: 568,
    height: 270,
    // imageWidth: 554,
    // imageHeight: 250,
    imageTop: 10,
    imageLeft: 7,
    imageRight: 7,
    imageBottom: 10,
    landscapeBorderRadius: '2.99295775%/6.2962963%',
    portraitBorderRadius: '6.2962963%/2.99295775%',
    landscapePath:
      'M 151.400024 3.199951 L 35 3.199951 C 15.799988 3.199951 0.200012 18.900024 0.099976 38.099976 L 0.099976 231.799988 C 0.099976 251 15.799988 266.599976 35 266.700012 L 59.400024 266.700012 C 59.599976 268.400024 61.099976 269.799988 62.900024 269.799988 L 84.099976 269.799988 C 85.900024 269.799988 87.400024 268.400024 87.599976 266.700012 L 114.200012 266.700012 C 114.400024 268.400024 115.900024 269.799988 117.700012 269.799988 L 165.400024 269.799988 C 167.200012 269.799988 168.700012 268.400024 168.900024 266.700012 L 183.099976 266.700012 C 183.299988 268.400024 184.799988 269.799988 186.599976 269.799988 L 234.299988 269.799988 C 236.099976 269.799988 237.599976 268.400024 237.799988 266.700012 L 533 266.700012 C 552.200012 266.700012 567.799988 251 567.900024 231.799988 L 567.900024 38.099976 C 567.900024 18.900024 552.200012 3.300049 533 3.199951 L 223.700012 3.199951 C 223.400024 1.5 222 0.199951 220.200012 0.199951 L 154.799988 0.199951 C 153.099976 0.199951 151.599976 1.5 151.400024 3.199951 Z M 533 10.199951 C 548.400024 10.199951 560.900024 22.699951 560.900024 38.099976 L 560.900024 231.799988 C 560.900024 247.200012 548.400024 259.700012 533 259.700012 L 35 259.700012 C 19.599976 259.700012 7.099976 247.200012 7.099976 231.799988 L 7.099976 38.099976 C 7.099976 22.699951 19.599976 10.199951 35 10.199951 L 533 10.199951 Z',
    portraitPath:
      'M 266.800049 151.400024 L 266.800049 35 C 266.800049 15.799988 251.099976 0.200012 231.900024 0.099976 L 38.200012 0.099976 C 19 0.099976 3.400024 15.799988 3.299988 35 L 3.299988 59.400024 C 1.599976 59.599976 0.200012 61.099976 0.200012 62.900024 L 0.200012 84.099976 C 0.200012 85.900024 1.599976 87.400024 3.299988 87.599976 L 3.299988 114.200012 C 1.599976 114.400024 0.200012 115.900024 0.200012 117.700012 L 0.200012 165.400024 C 0.200012 167.200012 1.599976 168.700012 3.299988 168.900024 L 3.299988 183.099976 C 1.599976 183.299988 0.200012 184.799988 0.200012 186.599976 L 0.200012 234.299988 C 0.200012 236.099976 1.599976 237.599976 3.299988 237.799988 L 3.299988 533 C 3.299988 552.200012 19 567.799988 38.200012 567.900024 L 231.900024 567.900024 C 251.099976 567.900024 266.699951 552.200012 266.800049 533 L 266.800049 223.700012 C 268.5 223.400024 269.800049 222 269.800049 220.200012 L 269.800049 154.799988 C 269.800049 153.099976 268.5 151.599976 266.800049 151.400024 Z M 259.800049 533 C 259.800049 548.400024 247.300049 560.900024 231.900024 560.900024 L 38.200012 560.900024 C 22.799988 560.900024 10.299988 548.400024 10.299988 533 L 10.299988 35 C 10.299988 19.599976 22.799988 7.099976 38.200012 7.099976 L 231.900024 7.099976 C 247.300049 7.099976 259.800049 19.599976 259.800049 35 L 259.800049 533 Z',
  },
  tablet: {
    width: 688,
    height: 521,
    // imageWidth: 666,
    // imageHeight: 502,
    imageLeft: 11,
    imageTop: 11,
    imageRight: 9,
    imageBottom: 9,
    landscapeBorderRadius: '2.03488372%/2.68714012%',
    portraitBorderRadius: '2.68714012%/2.03488372%',
    landscapePath:
      'M 84.400024 1 L 49.200012 1 C 48.299988 1 47.400024 1.699951 47 2.900024 L 34.200012 2.900024 C 17.5 2.900024 3.900024 16.5 3.799988 33.300049 L 3.799988 40.099976 C 2.099976 40.400024 0.900024 41.400024 0.900024 42.599976 L 0.900024 77.800049 C 0.900024 79 2.200012 80.099976 3.799988 80.300049 L 3.799988 490.599976 C 3.799988 507.299988 17.400024 520.900024 34.200012 521 L 656.700012 521 C 673.400024 521 687 507.400024 687.099976 490.599976 L 687.099976 33.199951 C 687.099976 16.5 673.5 2.900024 656.700012 2.800049 L 137.5 2.800049 C 137 1.699951 136.200012 0.900024 135.299988 0.900024 L 100.099976 0.900024 C 99.200012 0.900024 98.299988 1.599976 97.900024 2.800049 L 86.700012 2.800049 C 86.200012 1.800049 85.400024 1 84.400024 1 Z M 656.700012 11.900024 C 668.5 11.900024 678.099976 21.5 678.099976 33.300049 L 678.099976 490.599976 C 678.099976 502.400024 668.5 512 656.700012 512 L 34.200012 512 C 22.400024 512 12.799988 502.400024 12.799988 490.599976 L 12.799988 33.300049 C 12.799988 21.5 22.400024 11.900024 34.200012 11.900024 L 656.700012 11.900024 Z',
    portraitPath:
      'M 520 84.400024 L 520 49.200012 C 520 48.299988 519.300049 47.400024 518.099976 47 L 518.099976 34.200012 C 518.099976 17.5 504.5 3.900024 487.699951 3.799988 L 480.900024 3.799988 C 480.599976 2.099976 479.599976 0.900024 478.400024 0.900024 L 443.199951 0.900024 C 442 0.900024 440.900024 2.200012 440.699951 3.799988 L 30.400024 3.799988 C 13.700012 3.799988 0.099976 17.400024 0 34.200012 L 0 656.700012 C 0 673.400024 13.599976 687 30.400024 687.099976 L 487.800049 687.099976 C 504.5 687.099976 518.099976 673.5 518.199951 656.700012 L 518.199951 137.5 C 519.300049 137 520.099976 136.200012 520.099976 135.299988 L 520.099976 100.099976 C 520.099976 99.200012 519.400024 98.299988 518.199951 97.900024 L 518.199951 86.700012 C 519.199951 86.200012 520 85.400024 520 84.400024 Z M 509.099976 656.700012 C 509.099976 668.5 499.5 678.099976 487.699951 678.099976 L 30.400024 678.099976 C 18.599976 678.099976 9 668.5 9 656.700012 L 9 34.200012 C 9 22.400024 18.599976 12.799988 30.400024 12.799988 L 487.699951 12.799988 C 499.5 12.799988 509.099976 22.400024 509.099976 34.200012 L 509.099976 656.700012 Z',
  },
} as const

const { progressPercentage, scrollerContainerHeight } = useElementViewScrollProgress(
  container,
  // @ts-expect-error is not a problem?
  scrollRef,
)

const deviceDimensions = computed(() => devices[props.device.device_type])
const deviceOptions = computed(() =>
  orientation.value === 'landscape'
    ? {
        width: deviceDimensions.value.width,
        height: deviceDimensions.value.height,
        imageTop: deviceDimensions.value.imageTop,
        imageLeft: deviceDimensions.value.imageLeft,
        imageRight: deviceDimensions.value.imageRight,
        imageBottom: deviceDimensions.value.imageBottom,
      }
    : {
        width: deviceDimensions.value.height,
        height: deviceDimensions.value.width,
        imageTop: deviceDimensions.value.imageLeft,
        imageLeft: deviceDimensions.value.imageBottom,
        imageRight: deviceDimensions.value.imageTop,
        imageBottom: deviceDimensions.value.imageRight,
      },
)

const svgRef = ref<HTMLElement>()
const { height: svgHeight, width: svgWidth } = useElementBounding(svgRef)
const imageMarginYOffset = computed(() => {
  const aspectRatio = deviceOptions.value.width / deviceOptions.value.height
  return (svgHeight.value - svgWidth.value / aspectRatio) / 2
})

const maxHeightBySize = {
  small: 400,
  medium: 600,
  large: 800,
} as const

function getBorderRadiusStyles() {
  const borderRadius = deviceDimensions.value[`${orientation.value}BorderRadius`]
  return {
    borderRadius: borderRadius as string,
    ...(props.device.device_type === 'desktop'
      ? orientation.value === 'landscape'
        ? {
            borderBottomLeftRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
          }
        : {
            borderTopLeftRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
          }
      : {}),
  }
}
</script>

<template>
  <div
    class="relative isolate flex max-md:my-12"
    :style="{
      maxHeight:
        cover && maxHeightBySize[device.size] ? `${maxHeightBySize[device.size]}px` : '100%',
    }"
  >
    <div
      class="absolute inset-0 -z-10 overflow-hidden"
      :style="{
        '--margin-top': `calc(${imageMarginYOffset}px + ${deviceOptions.imageTop} / ${deviceOptions.width} * 100%)`,
        '--margin-left': `calc(${deviceOptions.imageLeft} / ${deviceOptions.width} * 100%)`,
        '--margin-bottom': `calc(${imageMarginYOffset}px + ${deviceOptions.imageBottom} / ${deviceOptions.width} * 100%)`,
        '--margin-right': `calc(${deviceOptions.imageRight} / ${deviceOptions.width} * 100%)`,
        'marginTop': 'calc(var(--margin-top) - 1px)',
        'marginLeft': 'calc(var(--margin-left) - 1px)',
        'marginBottom': 'calc(var(--margin-bottom) - 1px)',
        'marginRight': 'calc(var(--margin-right) - 1px)',
        ...getBorderRadiusStyles(),
      }"
    >
      <img
        ref="scrollRef"
        class="inset-0 w-full"
        :src="device.image_url"
        :style="{
          objectFit: cover ? 'cover' : undefined,
          transform: `translateY(calc(${progressPercentage * -1} * (100% - ${
            scrollerContainerHeight + 4
          }px)))`,
          ...(cover ? getBorderRadiusStyles() : {}),
        }"
      />
    </div>
    <svg
      ref="svgRef"
      :viewBox="`0 0 ${deviceOptions.width} ${deviceOptions.height}`"
      class="inset-0 z-10 mx-auto h-full"
    >
      <path :fill="device.frame_color" stroke="none" :d="deviceDimensions[`${orientation}Path`]" />
    </svg>
  </div>
</template>

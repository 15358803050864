<script lang="ts" setup>
import { DeviceScroller } from '#components'

defineProps<{
  device: InstanceType<typeof DeviceScroller>['$props']['device']
}>()

const containerRef = ref<HTMLElement>()
</script>

<template>
  <div ref="containerRef" class="relative flex justify-center">
    <div
      class="sticky top-0 mx-auto flex w-full max-w-container items-center justify-center gap-8 md:h-screen md:py-[10vh] lg:px-20 xl:gap-20"
    >
      <DeviceScroller
        :device="device"
        :container-ref="containerRef"
        class="h-80 max-w-[80vw] md:h-[80vh]"
      />
    </div>
  </div>
</template>

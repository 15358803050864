<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'klassik-deluxe',
  primary_color: '#FF6920',
  secondary_color: '#3D3D3D',
  header: {
    background: {
      image: `/projekt/klassik-deluxe/elbworx-projekt-klassik_deluxe-header-3840.webp`,
      video: `/projekt/klassik-deluxe/elbworx-projekt-klassik_deluxe-header-video.mp4`,
    },
    project: 'Klassik Deluxe',
    headline: 'Konzerte, die berühren',
    info_card: {
      description:
        'Kompakte All-in-One-Internet-Lösung für ein neues Veranstaltungsformat im Bereich der klassischen Musik',
      client: 'Great Innovations',
      roles: ['Webdesign', 'Corporate Design'],
      project_duration: 'Juni 2022 bis Juli 2022',
      project_url: 'https://klassik-deluxe.de/',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="text-primary">Die Herausforderung</h3>

      <p>
        Als ehemalige Mitglieder des Dresdner Kreuzchores haben Christian und Steffen die klassische
        Musik schon früh lieben gelernt — besonders die Chormusik. Für ihr neues
        Veranstaltungsformat
        <i>Klassik Deluxe</i>
        suchten sie eine kompakte All-in-One-Lösung im Web: Bewerbung künftiger Konzerte, Anbindung
        an den Kartenvorverkauf, Präsentation bisheriger Highlights — all das sollte die künftige
        Website leisten können. Und natürlich sollte die Passion der beiden Gründer spürbar werden,
        <i>einzigartige Momente mit hohem künstlerischen Anspruch</i>
        zu schaffen.
        <br />
        Grundzüge eines Corporate Designs waren bereits entwickelt, aber im Hinblick auf die Nutzung
        am Bildschirm noch nicht zu Ende gedacht. Die besondere Herausforderung lag darin, die
        gestalterische Grundidee aufzunehmen, kreativ weiterzuentwickeln und im Kontext eines
        Online-Auftritts voll zu entfalten.
      </p>
    </template>

    <DeviceSection
      :device="{
        device_type: 'tablet',
        frame_color: 'black',
        image_url: `${projectPath}elbworx-projekt-klassik_deluxe-startseite-2560.webp`,
        size: 'small',
      }"
    />
    <Padding class="flex justify-center">
      <Text>
        <h3>Die Lösung</h3>
        <p>
          Eine responsive Website — behutsam entwickelt auf Basis des vorhandenen Erscheinungsbilds
          — setzt aktuelle und vergangene Konzert-Angebote wirkungsvoll in Szene. Hochwertige
          Konzert- und Portraitfotos wecken Emotionen, transportieren Leidenschaft und rücken das
          künstlerische Schaffen der Musiker:innen in den Mittelpunkt.
        </p>
      </Text>
    </Padding>
    <Tilt>
      <Grid>
        <Tilt class="col-span-5 bg-[#F5F5F5]">
          <div class="px-10 py-20 md:px-20 md:py-40 xl:px-40 xl:py-60">
            <Image
              class="w-full rounded-3xl object-cover"
              :src="`${projectPath}elbworx-projekt-klassik_deluxe-filter_konzerte-960.webp`"
            />
          </div>
        </Tilt>
        <Tilt class="col-span-7 bg-[#428382] max-sm:-mt-tilt">
          <div class="px-10 py-20 sm:block md:px-20 md:py-40 xl:px-40 xl:py-60">
            <Image
              class="w-full rounded-3xl object-cover"
              :src="`${projectPath}elbworx-projekt-klassik_deluxe-konzerte-1280.webp`"
            />
          </div>
        </Tilt>
      </Grid>
    </Tilt>
    <Tilt class="-mt-tilt bg-primary px-10 py-20 sm:px-0 md:py-52">
      <Padding class="flex items-end justify-evenly gap-20 max-md:flex-wrap">
        <div class="w-5/6">
          <Image
            class="object-cover"
            :src="`${projectPath}elbworx-projekt-klassik_deluxe-eventseite-1280-erik.webp`"
          />
        </div>
        <div class="w-2/6 max-md:w-1/2">
          <Image :src="`${projectPath}elbworx-projekt-klassik_deluxe-eventseite-960-erik.webp`" />
        </div>
      </Padding>
    </Tilt>
    <Padding class="mt-8 flex justify-center">
      <Text>
        <h3>Die Umsetzung</h3>
        <p>
          Technisch realisiert wurde die Website von unserem IT-Partner
          <i><a class="font-light underline-offset-2" href="https://falcondev.de">falconDev</a></i>
          . Die „Falken“ stellten nicht nur eine pixelgenaue Umsetzung unseres Designs sicher,
          sondern sorgten auch für eine reibungslose Integration des Ticket-Systems
          <i>Reservix</i>
          .
        </p>
      </Text>
    </Padding>
  </Project>
</template>
